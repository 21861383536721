th,
td {
    // -webkit-user-select: none;
    // -webkit-touch-callout: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    font-size: 0.75rem;
}

@media print {
    body {
        display: none;
    }
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    font-size: 0.7rem;
}
